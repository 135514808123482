<template>
  <b-container class="mb-4" fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Banners</h4>
          <b-button variant="secondary" @click="changeRouteNew">
            <b-icon icon="plus" />
            NOVO BANNER
          </b-button>
        </div>
        <div class="d-flex flex-column">
          <b-table
            :items="banners"
            :fields="fields"
            :busy="isLoading"
            bordered
            striped
            hover
            @row-clicked="changeRouteEdit"
          >
            <template #cell(active)="row">
              {{ row.item.active ? "Ativo" : "Desativado" }}
            </template>
            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                title="Editar informações do banner"
                @click="changeRouteEdit(row.item)"
              >
                <b-icon icon="pencil" />
              </b-button>
              <b-button
                :variant="row.item.active ? 'success' : 'danger'"
                :title="
                  row.item.active ? 'Desativiar exibição' : 'Voltar a exibir'
                "
                size="sm"
                @click="openDeactivateModal(row.item)"
              >
                <b-icon icon="eye" />
              </b-button>
            </template>
          </b-table>
          <b-button v-if="loadMore" @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </div>
      </b-col>
      <custom-modal
        v-model="showDeactivateModal"
        title="Tem certeza?"
        message="Deseja desativar esse banner."
        @confirm="deactivateBanner"
      />
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomModal from "../../components/shared/CustomModal.vue";

export default {
  components: { CustomModal },
  name: "listBanners",
  data() {
    return {
      banner: {},
      showDeactivateModal: false,
      fields: [
        { label: "#", key: "id" },
        { label: "Descrição", key: "description" },
        { key: "link" },
        { key: "linkMobile" },
        { label: "", key: "actions" },
      ],
    };
  },
  computed: {
    ...mapState("banner", ["banners", "loadMore", "isLoading"]),
  },
  methods: {
    ...mapActions("banner", [
      "getAllBanners",
      "getMoreAllBanners",
      "hideBanner",
    ]),
    ...mapMutations("banner", ["clearBannerValues"]),
    ...mapMutations(["setOverlay"]),

    changeRouteNew() {
      this.clearBannerValues();
      this.$router.push("banners/criar");
    },
    changeRouteEdit({ id }) {
      this.clearBannerValues();
      this.$router.push(`banners/${id}/editar`);
    },
    openDeactivateModal(banner) {
      this.banner = banner;
      this.showDeactivateModal = true;
    },
    async getMore() {
      await this.getMoreAllBanners();
    },
    async deactivateBanner() {
      const {
        banner: { id, active },
      } = this;

      if (id) {
        await this.hideBanner({ id, active: !active });
      }

      this.showDeactivateModal = false;
    },
  },
  async created() {
    this.setOverlay(true);
    await this.getAllBanners();
    this.setOverlay(false);
  },
};
</script>

<style lang="scss" scoped></style>
